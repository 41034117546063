//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { validationMixin } from 'vuelidate'
import {email, required} from "vuelidate/lib/validators";

const touchMap = new WeakMap()

export default {

    name: 'Login',

    middleware: 'auth',
    auth: 'guest',

    mixins: [validationMixin],

    data() {
        return {
            email: '',
            password: '',
            loginFailed: false,
            loginErrors: {},
            loginErrorMessage: ''
        }
    },

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required
        }
    },

    watch: {
        '$v.$anyDirty': function (newVal, oldVal) {
            if(newVal === true) {
                this.resetLoginFailed()
            }
        }
    },

    methods: {

        async userLogin() {

            this.$v.$touch()

            if (this.$v.$invalid) {
                //window.scrollTo(0, 0)
                this.$scrollTo('#__nuxt', { offset: 0})
                return
            }

            //this.$waiter.start('page')
            //let toastLogin = this.$toast.show('Logging in...')

            //let notificationToasts = this.$toast.group({ /*...its options*/ })
            //notificationToasts.show('My Notification')

            let toastLoading = this.$toast.global.my_loading({message: 'Logging in...'})

            this.$v.$reset()

            try {
                let response = await this.$auth.loginWith('laravelSanctum', { data: { email: this.email, password: this.password} })
                this.$auth.setUser(response.data.user)
                this.$toast.clear()
                toastLoading.goAway(0)
                this.$toast.global.my_success({message: 'Logged in'})

                this.$sentry.setUser({ id: this.$auth.user.id })

                this.$bus.$emit('auth/login', this.$auth.user)

                this.$logger.debug('login success', response)

                this.$auth.redirect('home')

            } catch (e) {

                toastLoading.goAway(0)
                this.$logger.debug('login failed', e.response)

                this.handleLoginFailed(e)

            }

        },

        handleLoginFailed(error) {

            //window.scrollTo(0, 0)

            this.loginFailed = true

            if(error.response) {
                this.loginErrors = error.response.data.errors
                this.loginErrorMessage = error.response.data.message
            } else {
                this.loginErrorMessage = 'These credentials do not match our records'
            }

            this.$toast.global.my_error({message: this.loginErrorMessage})

        },

        resetLoginFailed() {
            this.loginFailed = false
            this.loginErrors = {}
            this.loginErrorMessage = ''
        },

        delayTouch($v) {
            $v.$reset()
            if (touchMap.has($v)) {
                clearTimeout(touchMap.get($v))
            }
            touchMap.set($v, setTimeout($v.$touch, 1000))
        },

    },

    computed: {

        hasLoginErrors() {
            return !this.$helper.isEmpty(this.loginErrors)
        }

    }


}


